// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-blogpost-jsx": () => import("./../../../src/pages/blogpost.jsx" /* webpackChunkName: "component---src-pages-blogpost-jsx" */),
  "component---src-pages-cgu-jsx": () => import("./../../../src/pages/cgu.jsx" /* webpackChunkName: "component---src-pages-cgu-jsx" */),
  "component---src-pages-cgv-jsx": () => import("./../../../src/pages/cgv.jsx" /* webpackChunkName: "component---src-pages-cgv-jsx" */),
  "component---src-pages-demo-2-jsx": () => import("./../../../src/pages/demo2.jsx" /* webpackChunkName: "component---src-pages-demo-2-jsx" */),
  "component---src-pages-demo-jsx": () => import("./../../../src/pages/demo.jsx" /* webpackChunkName: "component---src-pages-demo-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentions-jsx": () => import("./../../../src/pages/mentions.jsx" /* webpackChunkName: "component---src-pages-mentions-jsx" */),
  "component---src-pages-policy-jsx": () => import("./../../../src/pages/policy.jsx" /* webpackChunkName: "component---src-pages-policy-jsx" */)
}

